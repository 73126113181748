import {
  ApiCredentialsPart,
  ClickType,
  StatusCheck,
} from "../../../enums/profilePart.enum";
import { connect, ConnectedProps } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import CopyIcon from "@material-ui/icons/FilterNone";
import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { IApplication } from "../../../../apiApplications/interfaces/application.interface";
import ClientSettingsAPI from "../../../apis/clientSettings.api";
import { IReducerState } from "../../../../../reducer/interfaces/reducer.interface";
import { ClientSecretRequest } from "../../../models/clientSecretRequest.model";
import { AxiosError } from "axios";
import ToastAlert from "../../../../../common/submodules/toast/components/toastAlert";
import { ToastType } from "../../../../../common/submodules/toast/enums/toast-type.enum";
import { IToastAlert } from "../../../../../common/submodules/toast/interfaces/toast-alert.interface";
import { defaultToastAlert } from "../../../../../common/submodules/toast/constants/default-toast-alert.constant";
import { Button, Input, Modal } from "@material-ui/core";
import { HttpCode } from "../../../../../common/enums/http-code.enum";
import { onlyNumericalChars } from "../../../constants/regularExpression";
import Loader from "../../../../../common/components/loader";
import { ELoaderSize } from "../../../../../common/enums/loader.enum";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, WrappedComponentProps {
  application: IApplication;
  clientSecretRequest: ClientSecretRequest;
}

interface IStates {
  apiCodeState: ApiCredentialsPart;
  apiCodeStateInput: string;
  apiSecret: string;
  clickType: string;
  isFailed: boolean;
  toast: IToastAlert;
  clientSecretRequest: ClientSecretRequest;
  isDisabled: Boolean;
  confirmationModal:Boolean;
  resetConfirmationModal:boolean;
}

class ApiCredentialsContent extends Component<IProps, IStates> {
  clientId: string;
  userEmail: string;
  constructor(props: IProps) {
    super(props);
    this.state = {
      confirmationModal:false,
      resetConfirmationModal:false,
      apiCodeState:
        this.props.clientSecretRequest?.status || ApiCredentialsPart.NONE,
      apiCodeStateInput: "",
      apiSecret: "",
      clickType: ClickType.Send,
      isFailed: false,
      toast: defaultToastAlert,
      clientSecretRequest: this.props.clientSecretRequest,
      isDisabled: false,
    };
    (this.clientId =
      this.props.application?.credentials?.auth2Credentials || ""),
      (this.userEmail = this.props.user?.email);
  }
  private resetSecretOnVault(){
    this.setState({ isDisabled: true });
    if (this.clientId === "") {
      this.setState({
        isDisabled: false,
        toast: {
          isDisplayed: true,
          type: ToastType.ERROR,
          message: <FormattedMessage id="clientSettings.emptyClientId.label" />,
        },
      });
      return;
    }
    const clientSecretRequest = new ClientSecretRequest({
      clientId: this.clientId,
      status : ApiCredentialsPart.RESET_SECRET_ON_VAULT
    });
    ClientSettingsAPI.resetClientSecretRequest(clientSecretRequest).then(
      (res) => this.setState({ isDisabled: false, apiCodeState: res.status,resetConfirmationModal:false })
    );
  }
  private retrieveActivationCode() {
    this.setState({ isDisabled: true });
    if (this.clientId === "") {
      this.setState({
        isDisabled: false,
        toast: {
          isDisplayed: true,
          type: ToastType.ERROR,
          message: <FormattedMessage id="clientSettings.emptyClientId.label" />,
        },
      });
      return;
    }
    if (this.statusChecker(StatusCheck.VALIDATED)) {
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
      });
      ClientSettingsAPI.resetClientSecretRequest(clientSecretRequest).then(
        (res) => this.setState({ isDisabled: false, apiCodeState: res.status })
      );
    } else {
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
      });
      this.setState({
        toast: {
          isDisplayed: true,
          type: ToastType.SUCCESS,
          message: (
            <FormattedMessage id="clientSettings.confirmationCodeInputCorrect.label" />
          ),
        },
      });
      ClientSettingsAPI.initiateClientSecretRequest(clientSecretRequest)
        .then((res) => {
          this.setState({
            isDisabled: false,
            apiCodeState: res.status,
            clientSecretRequest: {
              ...clientSecretRequest,
              clientSecretRequestId: res.clientSecretRequestId,
            },
          });
        })
        .catch((error: AxiosError) => {
          this.setState({
            isDisabled: false,
            apiCodeState: ApiCredentialsPart.NONE,
            toast: {
              isDisplayed: true,
              type: ToastType.ERROR,
              message: <FormattedMessage id={error.message} />,
            },
          });
        });
    }
  }

  private retrieveClientSecret = (validationCode: string) => {
    this.setState({ isDisabled: true });
    if (
      validationCode.length === 6 &&
      onlyNumericalChars.test(validationCode)
    ) {
      if (this.clientId === "") {
        this.setState({
          isDisabled: false,
          toast: {
            isDisplayed: true,
            type: ToastType.ERROR,
            message: (
              <FormattedMessage id="clientSettings.emptyClientId.label" />
            ),
          },
        });
        return;
      }
      const clientSecretRequest = new ClientSecretRequest({
        clientId: this.clientId,
        validationCode,
      });
      this.setState({ apiCodeState: ApiCredentialsPart.INITIATED });
      ClientSettingsAPI.getClientSecretRequest(clientSecretRequest)
        .then((res) => {
          this.setState({
            isDisabled: false,
            clickType: ClickType.Check,
            apiSecret: res.clientSecret,
          });
          setTimeout(() => this.setState({ apiSecret: "" }), 5 * 60 * 1000);
        })
        .catch((error: AxiosError) => {
          switch (error.response?.status) {
            case HttpCode.BAD_REQUEST:
              this.setState({
                isDisabled: false,
                clickType: ClickType.Close,
                toast: {
                  isDisplayed: true,
                  type: ToastType.ERROR,
                  message: (
                    <FormattedMessage id="clientSettings.confirmationCodeInputIncorrect.label" />
                  ),
                },
              });
              break;
            case HttpCode.TOO_MANY_REQUEST:
              this.setState({
                isDisabled: false,
                isFailed: true,
                toast: {
                  isDisplayed: true,
                  type: ToastType.ERROR,
                  message: (
                    <FormattedMessage id="clientSettings.confirmationCodeInputIncorrect.label" />
                  ),
                },
              });
              break;
            case HttpCode.UNPROCESSABLE_ENTITY:
              this.setState({ apiCodeState: ApiCredentialsPart.EXPIRED });
              const clientSecretRequest = new ClientSecretRequest({
                clientId: this.clientId,
              });
              ClientSettingsAPI.resetClientSecretActivationCode(
                clientSecretRequest
              ).then(() => this.setState({ isDisabled: false }));
              break;
            default:
              break;
          }
        });
    } else
      this.setState({
        isDisabled: false,
        toast: {
          isDisplayed: true,
          type: ToastType.WARNING,
          message: (
            <FormattedMessage id="clientSettings.validationCodeDigits.label" />
          ),
        },
      });
  };
  private handleInputSecret = (e: any) => {
    const inputValue = e.target.value;
    if (/^\d{0,6}$/.test(inputValue)) {
      // If less than 6 digits are entered, update state
      this.setState({
        apiCodeStateInput: inputValue,
        clickType: ClickType.Send,
      });
      // If 6 digits are entered, trigger your function
      if (inputValue.length === 6) {
        this.retrieveClientSecret(inputValue);
      }
    }
  };
  private buttonDisplayer = () => {
    switch (this.state.clickType) {
      case ClickType.Send:
        return (
          <div className="clickableButton">
            <SendIcon
              onClick={() =>
                this.retrieveClientSecret(this.state.apiCodeStateInput)
              }
            />
          </div>
        );
      case ClickType.Check:
        return <CheckIcon />;
      case ClickType.Close:
        return <CloseIcon />;
    }
  };

  private labelDisplayer = () => {
    if (this.statusChecker(StatusCheck.EXPIRED))
      return "clientSettings.newValidationCode.label";
    else if (!!this.state.apiSecret)
      return "clientSettings.confirmationCodeMessageWithSecret.label";
    else return "clientSettings.confirmationCodeMessage.label";
  };

  private statusChecker = (status: string) => {
    switch (status) {
      case StatusCheck.VALIDATED:
        return this.state.apiCodeState === ApiCredentialsPart.VALIDATED;
      case StatusCheck.EXPIRED:
        return this.state.apiCodeState === ApiCredentialsPart.EXPIRED;
      case StatusCheck.NONE_OR_VALIDATED:
        return (
          this.state.apiCodeState === ApiCredentialsPart.NONE ||
          this.state.apiCodeState === ApiCredentialsPart.VALIDATED
        );
      case StatusCheck.EXPIRED_OR_INITIATED:
        return (
          this.state.apiCodeState === ApiCredentialsPart.EXPIRED ||
          this.state.apiCodeState === ApiCredentialsPart.INITIATED
        );
      case StatusCheck.RESETING_OR_PENDING:
        return (
          this.state.apiCodeState === ApiCredentialsPart.RESETING ||
          this.state.apiCodeState === ApiCredentialsPart.PENDING
        );
      case StatusCheck.REJECTED:
        return this.state.apiCodeState === ApiCredentialsPart.REJECTED;
      case StatusCheck.REJECTED2:
        return (
          this.state.clientSecretRequest.status === ApiCredentialsPart.REJECTED
        );
      case StatusCheck.CSS:
        return (
          this.state.apiCodeState === ApiCredentialsPart.EXPIRED ||
          this.state.apiCodeState === ApiCredentialsPart.INITIATED ||
          this.state.apiCodeState === ApiCredentialsPart.RESETING ||
          this.state.apiCodeState === ApiCredentialsPart.PENDING
        );
    }
  };

  private renderConfirmationCodeLabel() {
    const labelClass = [
      "confirmationCodeInputLabel",
      "confirmationCodeDiv",
      !!this.state.apiSecret ? "greenLabel" : "blueLabel",
    ].join(" ");
    const messageId = this.labelDisplayer();

    return (
      <span className={labelClass}>
        <FormattedMessage id={messageId} values={{EMAIL:this.props.clientSecretRequest.userEmail?? this.props.user?.email,type:this.state.clientSecretRequest.DirectReset?"A RESET Secret request is open":"A RETRIEVE Secret request is open"
          ,newOrOld : !this.state.clientSecretRequest.DirectReset?"to view your secret":"to view your new secret"
        }}/>
        {this.state.isDisabled && (
          <Loader size={ELoaderSize.SMALL} className="waiting-loader" />
        )}
      </span>
    );
  }
  private getModalContent = ()=>{
    if(!this.state.confirmationModal)
      return(
      <>
      <div style={{ marginLeft: "10px" }}>
            <span>
              <FormattedMessage id="clientSettings.secretReseting.message" values={{
            b: (chunks:string) => <b>{chunks}</b>
          }}/>
            </span> 
          </div>

          <div className="buttons-container">
            <Button style={{backgroundColor:"rgb(10, 32, 113)", border: "none"}} className="button"  onClick={(event) => { this.setState({confirmationModal:true})}}>
              <FormattedMessage id="clientSettings.secretReseting.button" />
            </Button>
          </div>
      </>
      );
    else
      return(<>
      <div style={{ marginLeft: "10px" }}>
            <span>
              <FormattedMessage id="clientSettings.secretReseting.areYouSure" />
            </span>
            <br/><br/>
            <span style={{color:"#9DA5C3"}}>
              <FormattedMessage id="clientSettings.secretReseting.sendEmail" values={{ email: this.props.user?.email }}/>
            </span> 

      </div>

          <div className="buttons-container">
            <Button className="button inverted"  onClick={(event) => {this.setState({resetConfirmationModal:false,confirmationModal:false}) }}>
              <FormattedMessage id="clientSettings.secretReseting.negativeButton" />
            </Button>
            <Button className="button "  onClick={(event) => {this.resetSecretOnVault() }}>
              <FormattedMessage id="clientSettings.secretReseting.positiveButton" />
            </Button>
          </div>
      </>);
  }
  render() {
    const placeholder = this.props.intl.formatMessage({
      id: "clientSettings.codeReceived.label",
    });
    const modalContent = this.getModalContent(); 
    const applicationName = this.props.application.name;
    return (
      <div className="apiCredentialsContent">
        <Modal open={this.state.resetConfirmationModal}>
        <div id="new-customer" className="success App-content">
          <div className="title">
            <FormattedMessage id="clientSettings.secretReseting.label" />
            <svg onClick={(event) => this.setState({ resetConfirmationModal: false,confirmationModal:false})} width="12" height="13" viewBox="0 0 12 13" fill="none" style={{ float: "right", marginTop: "3px" }} xmlns="http://www.w3.org/2000/svg">
              <path d="M0.850234 0.65L5.80023 5.6L10.7502 0.65C10.9502 0.45 11.2602 0.45 11.4602 0.65C11.6602 0.85 11.6602 1.16 11.4602 1.36L6.51023 6.3L11.4602 11.25C11.6602 11.45 11.6602 11.76 11.4602 11.96C11.2602 12.16 10.9502 12.16 10.7502 11.96L5.80023 7.01L0.850234 11.96C0.650234 12.16 0.340234 12.16 0.140234 11.96C-0.0597656 11.76 -0.0597656 11.45 0.140234 11.25L5.10023 6.3L0.150234 1.35C-0.0497656 1.16 -0.0497656 0.84 0.150234 0.65C0.340234 0.45 0.660234 0.45 0.850234 0.65Z" fill="#0A2071" />
            </svg>
          </div>
          {modalContent}
        </div>
        </Modal>
        <div className="title">{applicationName}</div>

        {!this.state.isFailed && (
          <>
            {this.statusChecker(StatusCheck.NONE_OR_VALIDATED) && (
              <>
              <div
                className="danger-zone"
                onClick={() => {
                  this.retrieveActivationCode();
                }}
              >
                <span className="retrieveApiSecretText">
                  <FormattedMessage id="clientSettings.retrieveApiSecret" />
                  {this.state.isDisabled && (
                    <Loader
                      size={ELoaderSize.SMALL}
                      className="waiting-loader"
                    />
                  )}
                </span>
              </div>
                
                <Button className="reset-credentials button inverted" onClick={()=>this.setState({ resetConfirmationModal:true })}>
                      <FormattedMessage id="clientSettings.resetCredentials" />
                </Button>
                </>
            )}
            {this.statusChecker(StatusCheck.EXPIRED_OR_INITIATED) && (
              <>
                <div>{this.renderConfirmationCodeLabel()}</div>
                <br />
                <div className="confirmationCodeContainer">
                  {this.state.apiSecret !== "" ? (
                    <div className="apiSecretLabel">
                      {this.state.apiSecret===undefined ?(
                        <div className="apiSecretError">
                          <span tabIndex={1}>
                          <FormattedMessage id="clientSettings.secretError.label" />
                          </span>
                        </div>
                      ) : (
                        <div className="apiSecretValue">
                          <span id="secret" contentEditable="true" tabIndex={1}>
                            {this.state.apiSecret}
                          </span>
                          <CopyIcon
                            className="copy-icon"
                            onClick={() =>
                              navigator.clipboard.writeText(this.state.apiSecret)
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="confirmationCodeInputDiv">
                      <div className="codeInputContainer">
                        <Input
                          className="confirmationCodeInput"
                          placeholder={placeholder}
                          value={this.state.apiCodeStateInput}
                          onChange={(e) => this.handleInputSecret(e)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {this.statusChecker(StatusCheck.RESETING_OR_PENDING) && (
              <div>
                <div className="confirmationCodeDiv">
                  <FormattedMessage id="clientSettings.resettingRequestMessage.label" values={{type:this.state.clientSecretRequest.DirectReset?"reset":"retreive"}}/>
                </div>
                <br />
                <div
                  className="danger-zone"
                  onClick={() => {
                    this.setState({
                      apiCodeState: ApiCredentialsPart.INITIATED,
                    });
                  }}
                >
                  <span className="retrieveApiSecretText">
                    <FormattedMessage id="clientSettings.receiveConfirmationCode" />
                  </span>
                </div>
              </div>
            )}
            {this.statusChecker(StatusCheck.REJECTED) && (
              <div>
                <span
                  className="confirmationCodeInputLabel confirmationCodeDiv"
                  style={{ color: "#B3B3B3" }}
                >
                  <FormattedMessage id="clientSettings.rejectedRequestMessage.label" />
                </span>
              </div>
            )}{" "}
          </>
        )}

        {this.state.isFailed && (
          <div>
            <span
              className={[
                "confirmationCodeInputLabel",
                "confirmationCodeDiv",
                "redLabel",
              ].join(" ")}
            >
              <FormattedMessage id="clientSettings.tooManyErrorRequests.label" />
            </span>
          </div>
        )}
        {this.state.toast.isDisplayed && (
          <div className="toast">
            <ToastAlert
              toastType={this.state.toast.type}
              toastMessage={this.state.toast.message}
              hideToast={() =>
                this.setState({
                  toast: { ...this.state.toast, isDisplayed: false },
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});
const connector = connect(mapState, {});
export default connector(injectIntl(ApiCredentialsContent));

import { ApiCredentialsPart } from "../enums/profilePart.enum";

export class ClientSecretRequest{
  clientSecretRequestId: string;
  client: string;
  clientId: string;
  applicationName: string;
  userEmail:string;
  submissionDate: Date;
  expirationDate: Date;
  numberOfAttempts: number;
  validationDate: Date;
  clientSecret: string;
  status:ApiCredentialsPart;
  validationCode?: string;
  DirectReset? : boolean;

  constructor(body: any) {
    this.clientSecretRequestId = body?.clientSecretRequestId;
    this.client = body?.client;
    this.clientId = body?.clientId;
    this.applicationName = body?.applicationName;
    this.submissionDate = body?.submissionDate;
    this.expirationDate = body?.expirationDate;
    this.numberOfAttempts = body?.numberOfAttempts;
    this.validationDate = body?.validationDate;
    this.clientSecret = body?.clientSecret;
    this.status = body?.status;
    this.validationCode = body?.validationCode;
    this.userEmail = body?.userEmail;
    this.DirectReset = body?.DirectReset ?? false;
  }
}
